import React, { useState, useRef } from "react";
import Swal from "sweetalert2";

import Button from "../Button";
import TextBox from "../TextBox";
import DateSelect from "../DateSelect";
import Loader from "../Loader";

import * as Statistics from '../modules/Statistics';

function ModeratorReport({setState}) {
    const [popupState, setPopupState] = useState('available');
    const [to, setTo] = useState();
    const [from, setFrom] = useState();
    const [discordID, setDiscordID] = useState();
    const [rbxID, setRbxID] = useState();

    const reportData = useRef();

    const submit = () => {
        if (!to || !from || !discordID || !rbxID) return;
        if (from > to) return Swal.fire({title: 'Error', icon: 'error', text: `Your from date is later than your to date... nice going retard.`, confirmButtonText: 'Ok'});
        setPopupState('loading');

        Statistics.getModeratorReport(from, to, rbxID, discordID)
            .then(res => {
                console.log(res);
                if (res.message == 'Success') {
                    reportData.current = res.data;
                } else {
                    Swal.fire({title: 'Error', icon: 'error', text: `Unexpected error occurred, either information was not supplied or the moderator could not be found. Did you fill out the Moderator Discord and Roblox ID out correctly?`, confirmButtonText: 'Ok'});
                }
                setPopupState('available');
            }).catch((err) => {
                console.log(err);
                setPopupState('available');
                Swal.fire({title: 'Error', icon: 'error', text: `Internal Server Error: ${err.message}`, confirmButtonText: 'Ok'});
            });
    }

    return <div onClick={(e) => {if (e.target != e.currentTarget) return; setState('closed')}} className='popup-background-center'>
        <div className='popup-container'>
            <h2>Moderator Report</h2>
            <div className='header'>
                <span><i style={{'marginRight': '4px'}} className='fa-solid fa-circle-exclamation'/> Information generated by live API: do not spam requests</span>
            </div>

            <div style={{'alignItems': popupState == 'loading' && 'center', 'marginTop': '-2px', 'gap': '12px', 'fontSize': '20px'}} className='content'>
                {popupState == 'loading' && <Loader style={{'border': 'none', 'backgroundColor': 'transparent', 'width': '200px', 'height': '200px'}}/>}

                {popupState == 'available' && <>
                    {!reportData.current && <>
                        <div style={{'animation': 'fadeIn 0.2s', 'display': 'flex', 'flexDirection': 'column', 'gap': '10px'}}>
                            <div className='embed-page-vertical-grouping'>
                                <span>Moderator Roblox ID</span>
                                <TextBox placeholder='Enter a valid Roblox ID' setState={setRbxID} children={rbxID}></TextBox>
                            </div>
                            <div className='embed-page-vertical-grouping'>
                                <span>Moderator Discord ID</span>
                                <TextBox placeholder='Enter a valid Discord ID' setState={setDiscordID} children={discordID}></TextBox>
                            </div>
            
                            <div className='embed-page-vertical-grouping'>
                                <span>From...</span>
                                <DateSelect value={from && (new Date((from * 1000) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 16) || undefined} onChange={(e) => {
                                    var date = new Date(e.target.value);
                                    setFrom(Math.round(date.getTime() / 1000));
                                }}/>
                            </div>
            
                            <div className='embed-page-vertical-grouping'>
                                <span>To...</span>
                                <DateSelect value={to && (new Date((to * 1000) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 16) || undefined} onChange={(e) => {
                                    var date = new Date(e.target.value);
                                    setTo(Math.round(date.getTime() / 1000));
                                }}/>
                            </div>
            
                            <div style={{'width': '100%'}} className='embed-page-horizontal-grouping'>
                                <Button onClick={submit} animation='raise' scheme='btn-confirm' style={{'width': '100%'}}><i style={{'marginRight': '3px'}} class="fa-solid fa-download"></i> Create</Button>
                                <Button onClick={() => setState('closed')} animation='raise' scheme='btn-cancel' style={{'width': '100%'}}>Cancel</Button>
                            </div>
                        </div>
                    </>}

                    {reportData.current && <>
                        <div className='profile-main'>
                            <div className='row'>
                                <div className='vertical-grouping'>
                                    <img style={{'maxHeight': '50%', 'maxWidth': '150px'}} src={reportData.current.Discord.user.displayAvatarURL}/>
                                </div>

                                <div style={{'width': '100%'}} className='vertical-grouping'>
                                    <div>
                                        <span><i style={{'marginRight': '2px'}} class='fa-solid fa-user'/> Roblox Username</span>
                                        <TextBox children={reportData.current.Roblox.user.name} disabled={true}/>
                                    </div>
                                
                                    <div>
                                        <span><i style={{'marginRight': '2px'}} class='fa-brands fa-discord'/> Discord Username</span>
                                        <TextBox children={reportData.current.Discord.user.username} disabled={true}/>
                                    </div>
                                </div>
                            </div>

                            <div style={{'marginTop': '25px', 'marginBottom': '20px', 'gap': '8px'}} className='vertical-grouping moderation-history'>
                                <h2>Moderator Statistics for Timeframe</h2>
                                <span>{reportData.current.Roblox.bans} <span style={{'color': '#c93434'}}>Roblox Bans</span></span>
                                <span>{reportData.current.Roblox.warnings} <span style={{'color': '#c93434'}}>Roblox Warnings</span></span>

                                <span>{reportData.current.Discord.bans} <span style={{'color': '#349fc9'}}>Discord Bans</span></span>
                                <span>{reportData.current.Discord.moderations} <span style={{'color': '#349fc9'}}>Discord Moderations</span></span>
                            </div>

                            <Button style={{'marginTop': '10px'}} animation='raise' scheme='btn-cancel' onClick={(e) => {setTo(); setFrom(); reportData.current = undefined; setPopupState('available');}}><i class="fa-solid fa-arrow-left-long"></i></Button>
                        </div>
                    </>}
                </>}
            </div>
        </div>
    </div>
}

export default ModeratorReport;
import React, { useState, useRef, useContext, useEffect } from 'react'; 
import ReactPaginate from 'react-paginate'; 
import Swal from 'sweetalert2';

import '../../../css/portalComponents/Statistics/ModerationReport.css';

import Button from '../../Button';
import TextBox from '../../TextBox';
import Loader from '../../Loader';
import DateSelect from '../../DateSelect';

import AuthContext from '../../modules/AuthContext';
import * as Statistics from '../../modules/Statistics';
import * as User from '../../modules/User';

function ModerationReport() {
    const authContext = useContext(AuthContext);

    const [state, setState] = useState('available');
    const stats = useRef();

    const [to, setTo] = useState();
    const [from, setFrom] = useState();
    
    const submitRange = () => {
        if (!to || !from) return;
        if (from > to) return Swal.fire({title: 'Error', icon: 'error', text: `Your from date is later than your to date... nice going retard.`, confirmButtonText: 'Ok'});
        setState('loading');

        Statistics.getModerationReport(from, to)
            .then(res => {
                if (res.message == 'Success') {
                    stats.current = res.data.sort((a,b) => {
                        return (b.StaffRank && b.StaffRank.rank || 0) - (a.StaffRank && a.StaffRank.rank || 0)
                    });
                    setState('stats-available');
                } else {
                    Swal.fire({title: 'Error', icon: 'error', text: `Something went wrong while trying to create this report. Try again later.`, confirmButtonText: 'Ok'});
                    setState('available');
                }
            })
            .catch(err => {
                Swal.fire({title: 'Error', icon: 'error', text: `Something went wrong while trying to create this report. Try again later.`, confirmButtonText: 'Ok'});
                setState('available');
            });
    }

    return (
        <>
            <div className='manage-roblox-bans-page'>
                {state == 'loading' &&
                    <Loader style={{'margin': 'auto'}}/>
                }
                
                {state == 'available' &&
                    <>
                        <div className='popup-container' style={{'margin-top': '50px'}}>
                            <h2>Moderation Report</h2>
                            <div className='header'>
                                <span><i style={{'marginRight': '4px'}} className='fa-solid fa-circle-exclamation'/> Information generated by live API: do not spam requests</span>
                            </div>
                
                            <div style={{'marginTop': '-2px', 'gap': '12px', 'fontSize': '20px'}} className='content'>
                                <div style={{'animation': 'fadeIn 0.2s', 'display': 'flex', 'flexDirection': 'column', 'gap': '10px'}}>
                                    <div className='embed-page-vertical-grouping'>
                                        <span>From...</span>
                                        <DateSelect value={from && (new Date((from * 1000) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 16) || undefined} onChange={(e) => {
                                            var date = new Date(e.target.value);
                                            setFrom(Math.round(date.getTime() / 1000));
                                        }}/>
                                    </div>
                    
                                    <div className='embed-page-vertical-grouping'>
                                        <span>To...</span>
                                        <DateSelect value={to && (new Date((to * 1000) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 16) || undefined} onChange={(e) => {
                                            var date = new Date(e.target.value);
                                            setTo(Math.round(date.getTime() / 1000));
                                        }}/>
                                    </div>
                    
                                    <div style={{'width': '100%'}} className='embed-page-horizontal-grouping'>
                                        <Button onClick={submitRange} animation='raise' scheme='btn-confirm' style={{'width': '100%'}}><i style={{'marginRight': '3px'}} class="fa-solid fa-download"></i> Create</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {stats.current && state == 'stats-available' &&
                    <>
                        <div className='manage-roblox-bans-container'>
                            <div className='manage-roblox-bans-container-header-row'>
                                <h1><i style={{'marginRight': '4px'}} class='fa-solid fa-table'/> Moderation Report</h1>
                            </div>

                            <div style={{'overflowY': 'auto', 'maxHeight': '80vh', 'boxSizing': 'border-box'}}>
                                <table style={{'width': '100%'}}>
                                    <tr id='header-row'>
                                        <th>Moderator</th>
                                        <th>Game Logs</th>
                                        <th>Discord Logs</th>
                                        <th>Tickets Claimed</th>
                                        <th>Requirements</th>
                                    </tr>

                                    {stats.current.map((k,i) => {
                                        var stat = stats.current[i];
                                        
                                        var gameLogs = stat.RobloxBans + stat.RobloxWarnings;
                                        var discordLogs = stat.DiscordModerations + stat.DiscordBans;
                                        var ticketLogs = stat.Tickets;

                                        var gameLogColor = (gameLogs <= 7 && '#c93434') || (gameLogs >= 10 && '#34c939') || '#f0be48';
                                        var discordLogColor = (discordLogs <= 12 && '#c93434') || (discordLogs >= 16 && '#34c939') || '#f0be48';
                                        var ticketLogColor = (ticketLogs <= 3 && '#c93434') || (ticketLogs >= 5 && '#34c939') || '#f0be48';

                                        return <tr>
                                            <td>
                                                <div>
                                                    {stat.User &&
                                                        <>
                                                            <img src={stat.Headshot}/>
                                                            <div className='vertical-grouping' style={{'alignItems': 'start', 'gap': '1px'}}>
                                                                <span>{stat.User.name}</span>
                                                                <span style={{'color': stat.StaffRank && '#f0be48' || '#c93434'}}>{stat.StaffRank && stat.StaffRank.name || 'Not Staff'}</span>
                                                            </div>
                                                        </>                                                       
                                                    || <>Failed to Load User {k}</>}
                                                </div>
                                            </td>
                                            <td style={{'textAlign': 'center', 'color': gameLogColor, 'fontSize': '23px'}}>
                                                {gameLogs}
                                            </td>
                                            <td style={{'textAlign': 'center', 'color': discordLogColor, 'fontSize': '23px'}}>
                                                {discordLogs}
                                            </td>
                                            <td style={{'textAlign': 'center', 'color': ticketLogColor, 'fontSize': '23px'}}>
                                                {ticketLogs}
                                            </td>
                                            <td style={{'backgroundColor': gameLogColor=='#34c939' && discordLogColor=='#34c939' && ticketLogColor=='#34c939' && '#34c939' || ((gameLogColor=='#f0be48' || discordLogColor=='#f0be48' || ticketLogColor=='#f0be48' || gameLogColor=='#34c939' || discordLogColor=='#34c939' || ticketLogColor=='#34c939') && '#f0be48') || '#c93434', 'width': '150px', 'opacity': '0.8'}}/>
                                        </tr>
                                    })}
                                </table>
                            </div>

                            <div className='manage-roblox-bans-table-bottom' style={{'height': '30px'}}>
                                <Button style={{'marginRight': 'auto', 'marginLeft': '5px'}} animation='raise' scheme='btn-cancel' onClick={(e) => {stats.current = undefined; setState('available');}}><i class="fa-solid fa-arrow-left-long"></i></Button>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default ModerationReport;
import React, { useState, useRef, useContext } from 'react';
import { getModerationProfile } from '../../js/modules/RobloxModerations';
import Swal from 'sweetalert2';

import '../../css/popups/RobloxUserLookup.css';
import '../../css/Popup.css';

import Button from '../Button';
import TextBox from '../TextBox';
import Loader from '../Loader';
import TextArea from '../TextArea';

import AuthContext from '../modules/AuthContext';
import { convertToLocal } from '../../../shared';

function RobloxUserLookupPopup({setState}) {
    const authContext = useContext(AuthContext);

    const [popupState, setPopupState] = useState('available');
    const [searchID, setSearchID] = useState();

    const moderationProfile = useRef();

    const searchProfile = () => {
        if (!searchID) return;
        setPopupState('loading');

        getModerationProfile(searchID)
            .then(response => {
                if (response.message == 'Success') {
                   moderationProfile.current = response.data; 
                }
                setPopupState('available');
            }).catch(err => {
                console.log(err);
                setPopupState('available');
            });
    };

    return (
        <>
            <div className='popup-background-center' onClick={(e) => {if (e.target != e.currentTarget || popupState == 'loading') return; setState('closed');}}>
                <div className='popup-container'>
                    <h2>User Lookup</h2>
                    <div className='header'>
                        <span><i style={{'marginRight': '4px'}} className='fa-solid fa-circle-exclamation'/> Information generated by live API: do not spam requests</span>
                    </div>

                    <div style={{'alignItems': popupState == 'loading' && 'center', 'marginTop': '-1px'}} className='content'>
                        {popupState == 'loading' &&
                            <Loader style={{'border': 'none', 'backgroundColor': 'transparent', 'width': '200px', 'height': '200px'}}/>
                        }
                        {popupState == 'available' &&
                            <>
                                {!moderationProfile.current &&
                                    <div className='vertical-grouping search-container'>
                                        <div>
                                            <span><i style={{'marginRight': '2px'}} class='fa-solid fa-user'/> Roblox ID</span>
                                            <TextBox onKeyUp={(e) => {
                                                if (e.key == 'Enter' || e.keyCode == 13) searchProfile();
                                            }} setState={setSearchID} placeholder='Enter a valid Roblox ID'/>
                                        </div>
                                    </div>
                                }

                                {moderationProfile.current &&
                                    <>
                                        <div className='profile-main'>
                                            <div className='row'>
                                                <div className='vertical-grouping'>
                                                    <img src={moderationProfile.current.avatarHeadshotUrl}/>
                                                </div>
        
                                                <div style={{'width': '100%'}} className='vertical-grouping'>
                                                    <div>
                                                        <span><i style={{'marginRight': '2px'}} class='fa-solid fa-user'/> Roblox Username</span>
                                                        <TextBox children={moderationProfile.current.rbxUser.name} disabled={true}/>
                                                    </div>
                                                
                                                    <div>
                                                        <span><i style={{'marginRight': '2px'}} class='fa-solid fa-id-card'/> Roblox ID</span>
                                                        <TextBox children={moderationProfile.current.rbxUser.id} disabled={true}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{'marginTop': '25px', 'marginBottom': '20px', 'gap': '8px'}} className='vertical-grouping moderation-history'>
                                                <h2>Moderation Profile</h2>
                                                {moderationProfile.current.ban ? <span style={{'color': '#c93434'}}><i style={{'marginRight': '2px'}} class="fa-solid fa-circle-info"></i> {moderationProfile.current.ban.banType.Type == 'Permanent' ? 'User is currently permanently banned' : 'Unbanned on ' + convertToLocal(moderationProfile.current.ban.bannedOn + moderationProfile.current.ban.banType.Time, true)}</span> : <span style={{'color': '#34c939'}}><i style={{'marginRight': '2px'}} class="fa-solid fa-circle-info"></i> User is not currently banned</span>}
                                                {moderationProfile.current.warnings.length == 0 &&
                                                    <span style={{'color': '#349fc9'}}><i style={{'marginRight': '2px'}} class="fa-solid fa-circle-info"></i> User has {moderationProfile.current.warnings.length} warning(s)</span>
                                                }

                                                {moderationProfile.current.warnings.length > 0 &&
                                                    <span style={{'color': '#349fc9'}}><i style={{'marginRight': '2px'}} class="fa-solid fa-circle-info"></i> User has {moderationProfile.current.warnings.length} warning(s): {moderationProfile.current.warnings.map((warning, i) => {
                                                        return warning.warnID
                                                    }).join(', ')}</span>
                                                }
                                            </div>

                                            <Button style={{'marginTop': '10px'}} animation='raise' scheme='btn-cancel' onClick={(e) => {setSearchID(' '); moderationProfile.current = undefined; setPopupState('available');}}><i class="fa-solid fa-arrow-left-long"></i></Button>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default RobloxUserLookupPopup;